<template>
  <b-modal
    id="profile-info-modal"
    title="Edit Personal Information"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Full Name"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.owner_name.$anyError : null"
          >
            <b-form-input v-model="form.owner_name" placeholder="Enter Full Name" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.owner_name.required : null">
              full name is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Department" class="mb-6">
            <b-form-input v-model="form.owner_department" placeholder="Enter Department" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone Extension (Optional)" class="mb-6">
            <b-form-input v-model="form.owner_extension" placeholder="Enter Extension" />
          </b-form-group>
        </b-col> <b-col cols="6">
          <b-form-group label="Mobile" class="mb-6">
            <b-form-input v-model="form.owner_mobile" placeholder="Enter Mobile" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { updateUserOwnerData } from '@/api/user'

export default {
  name: 'InfoModal',
  data () {
    return {
      form: {
        owner_name: '',
        owner_extension: '',
        owner_department: '',
        owner_mobile: ''
      }
    }
  },
  validations () {
    return {
      form: {
        owner_name: { required }
      }
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('profile-info-modal')
      this.form = data
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('profile-info-modal')
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      updateUserOwnerData(this.form).then(() => {
        this.$emit('update', this.form)
        this.hide()
      })
    }
  }
}
</script>
