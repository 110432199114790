<template>
  <b-modal
    id="change-password-modal"
    v-model="showModal"
    title="Change Password"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-form-group
        label="Current Password"
        class="mb-4"
        :state="$v.form.$anyDirty ? !$v.form.current_password.$anyError : null"
      >
        <b-form-input
          v-model="form.current_password"
          type="password"
          placeholder="Type current password"
        />
        <b-form-invalid-feedback :state="state($v.form.current_password.required)">
          current password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="state($v.form.current_password.match)">
          Current password does not match
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="New Password"
        class="mb-4"
        :state="$v.form.$anyDirty ? !$v.form.password.$anyError : null"
      >
        <b-form-input
          v-model="form.password"
          type="password"
          placeholder="Type new password"
        />
        <b-form-invalid-feedback :state="state($v.form.password.required)">
          new password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="state($v.form.password.notSameCurrent)">
          new password should not same as current password
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Confirm Password"
        class="mb-4"
        :state="$v.form.$anyDirty ? !$v.form.password_confirmation.$anyError : null"
      >
        <b-form-input
          v-model="form.password_confirmation"
          type="password"
          placeholder="Retype new password"
        />
        <b-form-invalid-feedback :state="state($v.form.password_confirmation.required)">
          confirm password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="state($v.form.password_confirmation.sameAsPassword)">
          password not match
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { resetPassword } from '@/api/user'
export default {
  name: 'ChangePasswordModal',
  data () {
    return {
      form: {
        current_password: '',
        password: '',
        password_confirmation: ''
      },
      showModal: false,
      passwordMatch: true
    }
  },
  validations () {
    return {
      form: {
        current_password: {
          required,
          match () {
            return this.passwordMatch
          }
        },
        password: {
          required,
          notSameCurrent () {
            return this.form.current_password !== this.form.password
          }
        },
        password_confirmation: {
          required,
          sameAsPassword () {
            return this.form.password === this.form.password_confirmation
          }
        }
      }
    }
  },
  methods: {
    show () {
      this.form.current_password = ''
      this.form.password = ''
      this.form.password_confirmation = ''
      this.passwordMatch = true
      this.$v.form.$reset()
      this.showModal = true
    },
    state (vaild) {
      return this.$v.form.$anyDirty ? vaild : null
    },
    submit (e) {
      this.passwordMatch = true
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      resetPassword(this.form).then(res => {
        if (res.data.success) {
          this.showModal = false
        } else {
          this.passwordMatch = false
        }
      }).catch(err => {
        console.log(err.response.data.error === 'Current password does not match')
        if (err.response.data.error === 'Current password does not match') { this.passwordMatch = false }
      })
    }
  }
}
</script>
