import apiService from '@/common/api.service'

export function getAccounts () {
  return apiService({
    url: '/api/account/list',
    method: 'get'
  })
}

export function createAccount (data) {
  return apiService({
    url: '/api/account',
    method: 'post',
    data
  })
}
export function updateAccount (data) {
  return apiService({
    url: '/api/account',
    method: 'patch',
    data
  })
}
export function deleteAccount (id) {
  return apiService({
    url: '/api/account',
    method: 'delete',
    params: { id }
  })
}
