<template>
  <b-modal
    id="change-owner-modal"
    title="Change Owner"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12" class="mb-4">
          The system will help you transfer the highest authority of enterprise management accounts.
          Note: After transfering is complete, this account will not be able to log into the system.
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email of New Owner"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.email.$anyError : null"
          >
            <b-form-input v-model="form.email" placeholder="Enter Email" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.required : null">
              email is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.email : null">
              email address format is incorrect
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'ChangeOwnerModal',
  data () {
    return {
      form: {
        email: ''
      }
    }
  },
  validations () {
    return {
      form: {
        email: { required, email }
      }
    }
  },
  methods: {
    show () {
      this.$bvModal.show('change-owner-modal')
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('change-owner-modal')
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      // updateUserOwnerData(this.form).then(() => {
      //   this.$emit('update')
      //   this.hide()
      // })
      this.hide()
    }
  }
}
</script>
