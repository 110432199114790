import apiService from '@/common/api.service'

export function getFeedbackList () {
  return apiService({
    url: '/api/feedback/list',
    method: 'get'
  })
}

export function createFeedback (data) {
  return apiService({
    url: '/api/feedback',
    method: 'post',
    data
  })
}
