<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-row>
          <div class="areawrap">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Personal Information
              </h2>

              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                <template #button-content>
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" fixed-width />
                </template>
                <b-dropdown-item href="#" @click.prevent="openInfoModal">
                  Edit Information
                </b-dropdown-item>
                <!-- <b-dropdown-item href="#" @click.prevent="openChangeOwnerModal">
                  Change Owner
                </b-dropdown-item> -->
                <b-dropdown-item href="#" @click.prevent="$refs.changePasswordModal.show()">
                  Change Password
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <b-row class="align-items-center">
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Full Name
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.owner_name }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Department
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.owner_department }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Role
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  Admin
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Email
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.owner_email }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Phone Extension
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.owner_extension }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Mobile
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.owner_mobile }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Status
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span
                  v-if="profileData.status === 0"
                  class="d-inline-block bg-primary text-white rounded-pill px-2 py-0"
                >
                  Pending
                </span>
                <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
                  Active
                </span>
              </b-col>
            </b-row>
          </div>
        </b-row>
        <b-row>
          <div class="areawrap">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Contact
              </h2>
              <b-link class="text-gray-500" href="#" @click.prevent="openEditContactModal">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
              </b-link>
            </div>

            <b-row class="align-items-center">
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Full Name
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ contactData.contact_name }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Email
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ contactData.contact_email }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Phone Extension
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ contactData.contact_extension }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-col>

      <b-col cols="4">
        <b-row>
          <div class="areawrap ml-4">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Corporate Information
              </h2>
              <b-link class="text-gray-500" href="#" @click.prevent="openCorporateModal">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
              </b-link>
            </div>

            <b-row class="align-items-center">
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Company Name
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.clinic_name }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Telephone
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.clinic_telphone }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Street Address
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.clinic_address }}
                </span>
              </b-col> <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  City
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ profileData.clinic_city }}
                </span>
              </b-col>
              <b-col cols="4" class="mb-5">
                <span class="font-size-sm text-primary">
                  Country
                </span>
              </b-col>
              <b-col cols="8" class="mb-5">
                <span>
                  {{ country(profileData.clinic_country) }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-row>

        <b-row>
          <div class="areawrap ml-4" style="width:100%">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Account List
              </h2>
              <b-link v-if="accounts.length < 19" class="text-gray-500" href="#" @click.prevent="onAccountCreateClick">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
              </b-link>
            </div>
            <b-row class="align-items-center table-base">
              <b-table borderless :fields="fields" :items="accounts">
                <template #head(name)>
                  NAME
                </template>
                <template #head(email)>
                  EMAIL
                </template>
                <template #head(action)>
                  <span />
                </template>
                <template #cell(action)="data">
                  <div class="text-right">
                    <b-link class="text-gray-500" href="#" @click.prevent="onAccountEditClick(data.item)">
                      <font-awesome-icon
                        :icon="['fas', 'pen']"
                        fixed-width
                      />
                    </b-link>
                    <b-link class="text-gray-500 ml-2" href="#" @click.prevent="onAccountDeleteClick(data.item.id)">
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                        fixed-width
                      />
                    </b-link>
                  </div>
                </template>
              </b-table>
            </b-row>
          </div>
        </b-row>
      </b-col>
      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex mb-4 align-items-center">
            <h2 class="mb-3">
              Service Provider
            </h2>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Company Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ serviceProvider.corporate_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Telephone
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ serviceProvider.corporate_telphone }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Street Address
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ serviceProvider.corporate_address }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                City
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ serviceProvider.corporate_city }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Country
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ country(serviceProvider.corporate_country) }}
              </span>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <div class="areawrap ml-4" style="width:100%">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Feedback
              </h2>
              <b-link class="text-gray-500" href="#" @click.prevent="onFeedbackCreateClick">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
              </b-link>
            </div>
            <b-row class="align-items-center table-base">
              <b-table borderless :fields="feedbackFields" :items="feedbacks">
                <template #head(created_at)>
                  DATE
                </template>
                <template #head(title)>
                  TITLE
                </template>
                <template #cell(created_at)="data">
                  {{ $dayjs(data.item.created_at).format('YYYY/MM/DD') }}
                </template>
              </b-table>
            </b-row>
          </div>
        </b-row>
      </b-col>
    </b-row>

    <info-modal ref="infoModal" @update="updatePersonInfo" />
    <corporate-modal ref="corporateModal" @update="getCorporateInfo" />
    <change-owner-modal ref="changeOwnerModal" />
    <change-password-modal ref="changePasswordModal" />
    <edit-contact-modal ref="editContactModal" @update="getContactData" />
    <account-action-modal ref="accountActionModal" @update="getAccounts" />
    <feedback-create-modal ref="feedbackCreateModal" @update="getFeedbacks" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import infoModal from '@/components/profile/infoModal'
import corporateModal from '@/components/profile/corporateModal'
import changeOwnerModal from '@/components/profile/changeOwnerModal'
import { getCorporateInfo, getServiceProviderData } from '@/api/user'
import ChangePasswordModal from '@/components/profile/changePasswordModal'
import editContactModal from '@/components/profile/editContactModal'
import { deleteAccount, getAccounts } from '@/api/account'
import { getFeedbackList } from '@/api/feedback'
import AccountActionModal from '@/components/profile/accountActionModal'
import feedbackCreateModal from '@/components/profile/feedbackCreateModal'

export default {
  name: 'Profile',
  components: {
    infoModal,
    corporateModal,
    changeOwnerModal,
    ChangePasswordModal,
    editContactModal,
    AccountActionModal,
    feedbackCreateModal
  },
  data () {
    return {
      profileData: {},
      serviceProvider: {},
      contactData: {
        contact_name: 'Catrina Chiu',
        contact_email: 'catrina.c@gmail.com',
        contact_extension: '202'
      },
      accounts: [],
      feedbacks: []
    }
  },
  computed: {
    fields () {
      return ['name', 'email', 'action']
    },
    feedbackFields () {
      return ['created_at', 'title']
    }
  },
  created () {
    this.getCorporateInfo()
    this.getServiceProviderData()
    this.getAccounts()
    this.getFeedbacks()
  },
  methods: {
    country (val) {
      return countryList.find(country => country.value === val)?.text
    },
    getCorporateInfo () {
      getCorporateInfo().then(res => {
        this.profileData = res.data.data
        this.contactData.contact_name = this.profileData.contact_name
        this.contactData.contact_email = this.profileData.contact_email
        this.contactData.contact_extension = this.profileData.contact_extension
      })
    },
    getContactData (item) {
      this.contactData = { ...item }
    },
    getServiceProviderData () {
      getServiceProviderData().then(res => {
        this.serviceProvider = res.data.data.partner
      })
    },
    getAccounts () {
      getAccounts().then(res => {
        this.accounts = res.data.data
      })
    },
    getFeedbacks () {
      getFeedbackList().then(res => {
        this.feedbacks = res.data.data
      })
    },
    openInfoModal () {
      const data = {
        owner_name: this.profileData.owner_name,
        owner_extension: this.profileData.owner_extension,
        owner_department: this.profileData.owner_department,
        owner_mobile: this.profileData.owner_mobile
      }
      this.$refs.infoModal.show(data)
    },
    openCorporateModal () {
      this.$refs.corporateModal.show(this.profileData)
    },
    openChangeOwnerModal () {
      this.$refs.changeOwnerModal.show()
    },
    openServiceProviderListModal () {
      this.$refs.serviceProviderListModal.show()
    },
    openEditContactModal () {
      const data = { ...this.contactData }
      this.$refs.editContactModal.show(data)
    },
    updatePersonInfo (data) {
      this.profileData.owner_name = data.owner_name
      this.profileData.owner_extension = data.owner_extension
      this.profileData.owner_department = data.owner_department
      this.profileData.owner_mobile = data.owner_mobile
    },
    onAccountCreateClick () {
      this.$refs.accountActionModal.show(null)
    },
    onAccountEditClick (item) {
      this.$refs.accountActionModal.show(item)
    },
    onAccountDeleteClick (id) {
      deleteAccount(id).then(() => this.getAccounts())
    },
    onFeedbackCreateClick () {
      this.$refs.feedbackCreateModal.show()
    }
  }
}
</script>
