<template>
  <b-modal
    id="account-action-modal"
    :title="title"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Name"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.name.$anyError : null"
          >
            <b-form-input v-model="form.name" placeholder="Enter Name" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.name.required : null">
              full name is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.email.$anyError : null"
          >
            <b-form-input v-model="form.email" placeholder="Enter Email" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.required : null">
              email is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.email.email : null">
              email address format is incorrect
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Password"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.password.$anyError : null"
          >
            <b-form-input
              v-model="form.password"
              type="password"
              placeholder="Type password"
            />
            <b-form-invalid-feedback :state="state($v.form.password.required)">
              password is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Confirm Password"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.confirmPassword.$anyError : null"
          >
            <b-form-input
              v-model="form.confirmPassword"
              type="password"
              placeholder="Retype password"
            />
            <b-form-invalid-feedback :state="state($v.form.confirmPassword.required)">
              confirm password is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="state($v.form.confirmPassword.sameAsPassword)">
              password not match
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { createAccount, updateAccount } from '@/api/account'

export default {
  name: 'EditContactModal',
  data () {
    return {
      form: {
        id: null,
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      edit: false
    }
  },
  validations () {
    return {
      form: {
        name: { required },
        email: { required, email },
        password: { required },
        confirmPassword: {
          required,
          sameAsPassword () {
            return this.form.password === this.form.confirmPassword
          }
        }
      }
    }
  },
  computed: {
    title () {
      return `${this.edit ? 'Edit' : 'Create'} Account`
    }
  },
  methods: {
    state (vaild) {
      return this.$v.form.$anyDirty ? vaild : null
    },
    show (data) {
      this.$bvModal.show('account-action-modal')
      if (data) {
        this.edit = true
        this.form = data
      }
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('account-action-modal')
      this.edit = false
      this.form = {
        id: null,
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      if (this.edit) {
        updateAccount(this.form).then(() => {
          this.formClose()
        })
      } else {
        createAccount(this.form).then(() => {
          this.formClose()
        })
      }
    },
    formClose () {
      this.$emit('update')
      this.hide()
    }
  }
}
</script>
