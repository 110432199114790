<template>
  <b-modal
    id="profile-corporate-modal"
    title="Edit Clinic Information"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <b-row class="px-4">
      <b-col cols="12">
        <b-form-group
          label="Clinic Name"
          class="mb-6"
          :state="$v.form.$anyDirty ? !$v.form.clinic_name.$anyError : null"
        >
          <b-form-input v-model="form.clinic_name" placeholder="Enter Company Name" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_name.required : null">
            Clinic Name is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Street Address"
          class="mb-6"
          :state="$v.form.$anyDirty ? !$v.form.clinic_address.$anyError : null"
        >
          <b-form-input v-model="form.clinic_address" placeholder="Enter Stress Address" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_address.required : null">
            street address is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <div class="mb-4">
          <b-form-group
            label="Country"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.clinic_country.$anyError : null"
          >
            <b-form-select
              v-model="form.clinic_country"
              :options="countryList"
              :state="$v.form.$anyDirty ? !$v.form.clinic_country.$anyError : null"
              @change="onCountryChange"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Select Country
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_country.required : null">
              country is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="City"
          class="mb-6"
          :state="$v.form.$anyDirty ? !$v.form.clinic_city.$anyError : null"
        >
          <b-form-input v-model="form.clinic_city" placeholder="Enter City" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_city.required : null">
            city is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Telephone"
          class="mb-6"
          :state="$v.form.$anyDirty ? !$v.form.clinic_telphone.$anyError : null"
        >
          <b-form-input v-model="form.clinic_telphone" placeholder="Enter Telephone" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.clinic_telphone.required : null">
            telephone is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <div class="mb-4">
          <b-form-group
            label="Facility"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.clinic_country.$anyError : null"
          >
            <b-form-select
              v-model="form.facility"
              label="Facility"
              :options="facilityList"
              :state="$v.form.$anyDirty ? !$v.form.facility.$anyError : null"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Select Facility
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.facility.required : null">
              facility is required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-col>
      <b-col v-if="form.facility===99" cols="6">
        <div class="mb-4">
          <b-form-input v-model="form.facility_other" placeholder="Enter Facility Detail" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.facility_other.req : null">
            Facility Detail is required
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import facilityList from '@/common/facilityList'
import { updateUserCorporateData } from '@/api/user'

export default {
  name: 'InfoModal',
  data () {
    return {
      form: {
        clinic_name: '',
        clinic_country: '',
        clinic_city: '',
        clinic_address: '',
        clinic_telphone: '',
        facility: null,
        facility_other: ''
      }
    }
  },
  validations () {
    return {
      form: {
        clinic_name: { required },
        clinic_country: { required },
        clinic_city: { required },
        clinic_address: { required },
        clinic_telphone: { required },
        facility: { required },
        facility_other: {
          req () {
            return this.form.facility !== 99 ||
            (this.form.facility === 99 && this.form.facility_other !== undefined && this.form.facility_other)
          }
        }
      }
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    facilityList () {
      return facilityList
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('profile-corporate-modal')
      this.form = data
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('profile-corporate-modal')
    },
    onCountryChange (value) {
      this.form.clinic_telphone = `+${getCountryCode(value)}`
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      updateUserCorporateData(this.form).then(() => {
        this.$emit('update')
        this.hide()
      })
    }
  }
}
</script>
