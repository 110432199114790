<template>
  <b-modal
    id="edit-contact-modal"
    title="Edit Contact"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Full Name"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.contact_name.$anyError : null"
          >
            <b-form-input v-model="form.contact_name" placeholder="Enter Full Name" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_name.required : null">
              full name is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.contact_email.$anyError : null"
          >
            <b-form-input v-model="form.contact_email" placeholder="Enter Email" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_email.required : null">
              email is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_email.email : null">
              email address format is incorrect
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone Extension (Optional)" class="mb-0">
            <b-form-input v-model="form.contact_extension" placeholder="Enter Extension" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_extension.numeric : null">
              phone extension allow number only
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators'
import { updateUserContact } from '@/api/user'

export default {
  name: 'EditContactModal',
  data () {
    return {
      form: {
        contact_name: '',
        contact_email: '',
        contact_extension: ''
      }
    }
  },
  validations () {
    return {
      form: {
        contact_name: { required },
        contact_email: { required, email },
        contact_extension: { numeric }
      }
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('edit-contact-modal')
      this.form = data
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('edit-contact-modal')
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      updateUserContact(this.form).then(() => {
        this.$emit('update', this.form)
        this.hide()
      })
    }
  }
}
</script>
